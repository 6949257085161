import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import planStore from '../store/planStore';
import { ROUTES } from '../app.constant';
// import { getOrderToken, sendDataToGoogleAnalytics } from '../app.utils';
import { useI18next } from "gatsby-plugin-react-i18next"
import loaderStore from '../store/loaderStore';
import getQueryParams from '../components/shared/getQueryParam';

const HandlePaymentResposne = ({ search }) => {
    const { navigate } = useI18next()
    const { status, orderNumber } = search;
    orderNumber && planStore.updateOrderNo(orderNumber);
    if (typeof window !== 'undefined') {
        localStorage.setItem('previousUrl', window.location.pathname);
    }
    useEffect(() => {
        document.title = '';
        // force hiding loader
        loaderStore.showLoader(false)

        // ***Redirect with translate code will append by boltpay
        switch (status) {
            case "success":
                navigate(ROUTES.PAYMENT_SUCCESS)
                break
            case "fail":
                navigate(ROUTES.PAYMENT_FAIL)
                break
            default:
                break
        }

        return () => {
            // sendDataToGoogleAnalytics('handle_payment_response', planStore.orderStatus, status);
        }
    }, [])

    return (<></>)
}

HandlePaymentResposne.propTypes = {
    search: PropTypes.object,
}

export default getQueryParams(HandlePaymentResposne)